<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5"
            >Return To {{ prevProcess.name }}</v-card-title
        >
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid" class="mb-2 mx-1">
                <v-row no-gutters>
                    <v-col
                        cols="12"
                        xl="6"
                        lg="6"
                        md="6"
                        class="d-flex align-center"
                    >
                        <div class="d-flex align-center">
                            <v-icon class="mr-2">mdi-database-outline</v-icon>
                            <p class="ma-0">
                                Available Quantity: {{ availableQty }}
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6">
                        <v-text-field
                            v-model="qtyToSend"
                            prepend-icon="mdi-database-export-outline"
                            label="Quantity To Send*"
                            type="number"
                            required
                            :rules="[
                                rules.required,
                                rules.minValue,
                                maxValue(),
                            ]"
                            class="mt-4 pa-0"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions class="pb-3 pt-0">
            <v-btn text color="secondary" @click="close">
                Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="!valid" @click="send">
                Send
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'

export default {
    name: 'PrevProcess',
    props: {
        item: {
            type: Object,
            required: true,
        },
        workOrder: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        valid: false,
        loading: false,
        prevProcess: undefined,
        nextProcess: undefined,
        availableQty: 0,
        qtyToSend: undefined,
        processes: [],
        rules: {
            required: v => !!v || 'The value is required',
            minValue: v => v > 0 || 'The value must be greater than 0',
        },
    }),
    async mounted() {
        this.item.processes.forEach(processId => {
            let process = this.item.dataProcesses.find(p => p.id == processId)
            this.processes.push(process)
        })
        const processIndex = this.processes.findIndex(
            p => p.id == this.user.process.id
        )
        if (processIndex > 0) {
            this.nextProcess = this.processes[processIndex]
            this.prevProcess = this.processes[processIndex - 1]
            this.calculateValues()
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        calculateValues() {
            try {
                let totalProduced = 0
                let totalDelivered = 0
                if (this.item.deliveries) {
                    let delivered = this.item.deliveries.filter(
                        register =>
                            register.nextProcess.id == this.nextProcess.id &&
                            register.receivedBy
                    )
                    totalDelivered = delivered.reduce(
                        (total, register) => total + register.qty,
                        0
                    )
                }

                if (this.item.production) {
                    let produced = this.item.production.filter(
                        register => register.process.id == this.nextProcess.id
                    )
                    totalProduced = produced.reduce(
                        (total, register) => total + register.qty,
                        0
                    )
                }
                this.availableQty = totalDelivered - totalProduced
            } catch (error) {
                console.error(error)
            }
        },
        close() {
            this.$emit('closeDialog')
        },
        matchCode() {
            return !!(
                this.code &&
                this.confirmCode &&
                this.code === this.confirmCode
            )
        },
        async send() {
            try {
                this.loading = true
                const items = await API.sendToNextProcess({
                    partNumberId: this.item.partNumberId,
                    workOrderId: this.workOrder.id,
                    qty: -this.qtyToSend,
                    prevProcess: this.prevProcess,
                    nextProcess: this.nextProcess,
                })
                this.$emit('closeDialog', items)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        maxValue() {
            if (Number(this.qtyToSend) > this.availableQty) {
                return `The value cannot be greater than ${this.availableQty}.`
            } else {
                return true
            }
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
</style>
